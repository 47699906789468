import React, { useState, useEffect } from "react";
import config from "../config/config";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Viewdoc_modal from "./View-document";
import { getCandidateByEmailName } from "./helper/Api_Helper";

const IMAGE_PATH = config.IMAGE_PATH;
export default function Verify_docs_modal(props) {
  const [viewmodalShow, setViewModalShow] = useState(false);
  const [employeeDoc, setEmployeeDoc] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedDocName, setSelectedDocName] = useState("");

  useEffect(() => {
    const fetchEmployeeDoc = async () => {
      try {
        const response = await getCandidateByEmailName(props.empMail);
        if (response && response.data && Array.isArray(response.data.docs)) {
          setEmployeeDoc(response.data.docs);
          setEmployeeName(response.data.name);
        } else {
          setEmployeeDoc([]);
        }
      } catch (error) {
        console.error('Error fetching documents:', error);
        setEmployeeDoc([]);
      }
    };

    fetchEmployeeDoc();
  }, [props.empMail]);

   const handleViewClick = (fileName, docName) => {
    setSelectedImage(IMAGE_PATH + fileName);
    setSelectedDocName(docName);
    setViewModalShow(true);
  };

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="border-0 px-4" closeButton>
          <Modal.Title>Documents Attached</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-top">
          <Table className="docstable">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Document Name</th>
                <th>Category</th>
                <th>Attached Date</th>
                <th>Uploaded By</th>
              </tr>
            </thead>
            <tbody>
              {employeeDoc.length > 0 ? (
                employeeDoc.map((doc, index) => (
                  <tr key={doc._id}>
                    <td>{index + 1}</td>
                    <td>{doc.doc_name}</td>
                    <td>{doc.doc_category}</td>
                    <td>{new Date(doc.add_date).toLocaleDateString()}</td>
                    <td>{employeeName}</td>
                    <td>
                      <div className="d-flex flex-row gap-3 justify-content-end">
                        <button
                            className="viewfile"
                            onClick={() => handleViewClick(doc.file_name, doc.doc_name)}
                            >
                            View
                            </button>
                        <button className="set_verify">Verify</button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No documents available</td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="read-btn d-flex justify-content-center align-items-center my-3">
            <button className="px-5 w-small btn">Verify All</button>
          </div>
        </Modal.Body>
      </Modal>
      <Viewdoc_modal show={viewmodalShow} onHide={() => setViewModalShow(false)} imageUrl={selectedImage} docName={selectedDocName} />

    </>
  );
}
