import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { setInterviewers } from "../slices/ScheduleInterviews/ScehduleInterviews";
import { useDispatch , useSelector } from "react-redux";



const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff !important",
      borderColor: state.isFocused
        ? "#D2C9FF"
        : state.isHovered
        ? "#80CBC4"
        : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #D2C9FF" : "none",
      "&:hover": {
        borderColor: "#D2C9FF",
      },
      // maxWidth: '%',
    //   width: "200px",
      height: "44px",
      // borderTopLeftRadius: '0',
      // borderBottomLeftRadius: '0'
    }),
    menu: (provided) => ({
      ...provided,
      borderTop: "1px solid #D2C9FF",
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #D2C9FF",
      color: state.isSelected ? "#fff" : "#000000",
      backgroundColor: state.isSelected
        ? "#4CAF50"
        : state.isFocused
        ? "#80CBC4"
        : provided.backgroundColor,
      "&:hover": {
        backgroundColor: "#80CBC4",
        color: "#fff",
      },
    }),
  };

const AsyncMultiSelectInput = ({loadOptions}) => {
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const {  interviewers } = useSelector((state) => state.interview)

  const handleChange = (selected) => {

    console.log(selected , 'here is logs data')
    setSelectedOptions(selected);
    let result = selected?.map((item) => {
        let result = {
            employee_name:item?.label,
            employee_id:item?.value,
            designation:item?.designation
        }
        return result
    })
    dispatch(setInterviewers(result))
  };

  useEffect(() => {
    if(interviewers?.length > 0 ){
        let result = interviewers?.map((item) => {
             return {
                label:item?.employee_name,
                value:item?.employee_id,
                designation:item?.designation
            }
        })
        setSelectedOptions(result)
    }
  } , [interviewers])

  return (
    <AsyncSelect
      isMulti
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      value={selectedOptions}
      onChange={handleChange}
      placeholder="Search Interviews..."
      closeMenuOnSelect={false} // Keeps the dropdown open after selecting an option
      classNamePrefix="react-select"
      styles={customStyles}
    />
  );
};

export default AsyncMultiSelectInput;
