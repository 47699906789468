import React from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { CiCalendar } from "react-icons/ci";

export default function EmployeeJourney({ employeeDoc }) {

    return (
        <>
            <div className="row my-3" data-aos="fade-in" data-aos-duration="3000">
                <div className="col-lg-12">
                    <div className="sitecard pr-0 h-100 ps-0 pt-4">
                        <h4 className="ms-3 mb-3">Employee Journey</h4>
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-column gap-2 mt-1 scroller-content w-100 smalldata infobox">
                                <Table className="candd_table">
                                    <thead>
                                        <tr>
                                            <th className="text-center fw-medium">Update Date</th>
                                            <th className="text-center fw-medium">
                                                Project
                                            </th>
                                            <th className="text-center fw-medium">
                                                Designation
                                            </th>
                                            <th className="text-center fw-medium">
                                                Department
                                            </th>
                                            <th className="text-center fw-medium">
                                                Location
                                            </th>
                                            <th className="text-center fw-medium">
                                                CTC per Annum (Monthly CTC)
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            <td>12/02/2024</td>
                                            <td>Gail Project</td>
                                            <td>Manager</td>
                                            <td>Associate Manager</td>
                                            <td>Noida</td>
                                            <td>₹ 30000 </td>
                                        </tr>
                                        <tr >
                                            <td>12/02/2024</td>
                                            <td>Gail Project</td>
                                            <td>Manager</td>
                                            <td>Associate Manager</td>
                                            <td>Noida</td>
                                            <td>₹ 30000 </td>
                                        </tr>
                                        <tr >
                                            <td>12/02/2024</td>
                                            <td>Gail Project</td>
                                            <td>Manager</td>
                                            <td>Associate Manager</td>
                                            <td>Noida</td>
                                            <td>₹ 30000 </td>
                                        </tr>
                                        <tr >
                                            <td>12/02/2024</td>
                                            <td>Gail Project</td>
                                            <td>Manager</td>
                                            <td>Associate Manager</td>
                                            <td>Noida</td>
                                            <td>₹ 30000 </td>
                                        </tr>
                                        <tr >
                                            <td>12/02/2024</td>
                                            <td>Gail Project</td>
                                            <td>Manager</td>
                                            <td>Associate Manager</td>
                                            <td>Noida</td>
                                            <td>₹ 30000 </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
