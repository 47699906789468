
import React from "react";

export default function Emp_info({ data }) {
    const educationData = (Array.isArray(data.education_data) && data.education_data.length > 0) ? data.education_data : [];
    const experienceData = (Array.isArray(data.experience_info) && data.experience_info.length > 0) ? data.experience_info : [];
    const calculateDateDifference = (fromDate, toDate) => {
        if (!fromDate || !toDate) return { days: 0, months: 0 };

        const startDate = new Date(fromDate);
        const endDate = new Date(toDate);

        // Calculate the difference in days
        const timeDiff = endDate - startDate;
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        // Calculate the difference in months
        let monthsDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12;
        monthsDiff += endDate.getMonth() - startDate.getMonth();

        return { days: daysDiff, months: monthsDiff };
    };

    return (
        <>
            <div className="row my-3" data-aos="fade-in" data-aos-duration="3000">
                <div className="col-lg-12">
                    <div className="sitecard pr-0">
                        <div className="infobox">
                            <h5>Employment Information</h5>
                            <div className="threecolm infotext w-100 mt-3">
                                <div className="infos">
                                    <h6>Full name</h6>
                                    <p>{data.name}</p>
                                </div>
                                <div className="infos">
                                    <h6>Father’s Name</h6>
                                    <p>{data.father_name}</p>
                                </div>
                                <div className="infos">
                                    <h6>Gender</h6>
                                    <p>{data.gender}</p>
                                </div>
                                <div className="infos">
                                    <h6>Marital Status</h6>
                                    <p>{data.marital_status}</p>
                                </div>
                                <div className="infos">
                                    <h6>Date of Joining</h6>
                                    <p>{data.joining_date}</p>
                                </div>
                                <div className="infos">
                                    <h6>Probation Completion Date</h6>
                                    <p>{data.probation_complete_date}</p>
                                </div>
                                <div className="infos">
                                    <h6>Designation</h6>
                                    <p>{data.designation}</p>
                                </div>
                                <div className="infos">
                                    <h6>Branch </h6>
                                    {Array.isArray(data.branch) && data.branch.length > 0 ? (
                                        data.branch.map((item, index) => (
                                            <p key={index}>{item}</p>
                                        ))
                                    ) : (
                                        <p>N/A</p>
                                    )}
                                </div>
                                <div className="infos">
                                    <h6>Occupation</h6>
                                    <p>{data.occupation}</p>
                                </div>
                                <div className="infos">
                                    <h6>Department</h6>
                                    <p>{data.department}</p>
                                </div>
                                <div className="infos">
                                    <h6>Division</h6>
                                    <p>{data.division}</p>
                                </div>
                                <div className="infos">
                                    <h6>Grade</h6>
                                    <p>{data.grade}</p>
                                </div>
                            </div>
                            <h5>Account Details</h5>
                            <div className="infotext threecolm w-100 mt-3">
                                <div className="infos">
                                    <h6>Bank Name</h6>
                                    <p>{data.bank_name}</p>
                                </div>
                                <div className="infos">
                                    <h6>Branch Name</h6>
                                    <p>{data.bank_branch}</p>
                                </div>
                                <div className="infos">
                                    <h6>Bank Ifsc Code</h6>
                                    <p>{data.ifsc_code}</p>
                                </div>
                                <div className="infos">
                                    <h6>Bank Account Number</h6>
                                    <p>{data.bank_account_number}</p>
                                </div>
                                <div className="infos">
                                    <h6>Bank Type</h6>
                                    <p>{data.bank_account_type}</p>
                                </div>
                            </div>
                            <h5>TDS/PF/ESI/PT Details</h5>
                            <div className="infotext threecolm w-100 mt-3">
                                <div className="infos">
                                    <h6>Pan Number</h6>
                                    <p>{data.pan_number}</p>
                                </div>
                                <div className="infos">
                                    <h6>PF Number</h6>
                                    <p>{data.pf_number}</p>
                                </div>
                                <div className="infos">
                                    <h6>UAN Number</h6>
                                    <p>{data.uan_number}</p>
                                </div>
                                <div className="infos">
                                    <h6>ESI Number</h6>
                                    <p>{data.esi_number}</p>
                                </div>
                            </div>
                            <h5>Education</h5>
                            <div className="infotext w-100 threecolm">
                                {educationData.length > 0 ? (
                                    educationData.map((item) => (
                                        <div className="infos" key={item._id}>
                                            {item.degree_certificates ? <h6>{item.degree_certificates}</h6> : null}
                                            {item.passing_year ? <p>{item.passing_year}</p> : null}
                                            {item.marks ? <p>{item.marks}</p> : null}
                                        </div>
                                    ))
                                ) : (
                                    <p></p>
                                )}

                                {experienceData.length > 0 ? (
                                    experienceData.map((item) => {
                                        // Check if at least one field has a value
                                        const hasContent = item.employer_name || item.designation;

                                        return hasContent ? (
                                            <div className="infos" key={item._id}>
                                                {item.employer_name ? <h6>{item.employer_name}</h6> : null}
                                                {item.designation ? <p>{item.designation}</p> : null}
                                                <p>Duration: {item.from_date && item.to_date ? `${calculateDateDifference(item.from_date, item.to_date).months} months (${calculateDateDifference(item.from_date, item.to_date).days} days)` : 'N/A'}</p>
                                            </div>
                                        ) : null;
                                    })
                                ) : (
                                    <p></p>
                                )}

                                <div className="infos">
                                    <h6>Current CTC</h6>
                                    <p>{data.ctc}</p>
                                </div>
                                <div className="infos">
                                    <h6>Notice Period</h6>
                                    <p>-</p>
                                </div>
                                <div className="infos">
                                    <h6>Last Working day </h6>
                                    <p>{data.valid_till}</p>
                                </div>
                                <div className="infos">
                                    <h6>Joining Date</h6>
                                    <p>{data.joining_date}</p>
                                </div>
                                <div className="infos">
                                    <h6>Reference employee</h6>
                                    <p>-</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
