import React, { useState } from 'react';
import { IoLocationOutline, IoSettingsOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import Dropdown from "react-bootstrap/Dropdown";
import BudgetModal from "./BudgetModal";
import ExtendDurationModal from "./ExtendDurationModal";
import { truncateWithEllipsis } from '../../utils/common';
import config from '../../config/config';
import { IoMdCloseCircleOutline } from "react-icons/io";



const ProjectBox = (props) => {

    const itemData = props.data;

    console.log(itemData , 'this is project Detials');

    const [modalShow, setModalShow] = useState(false);
    const [modalShowOne, setModalShowOne] = useState(false);

    const locationString = itemData && Array.isArray(itemData.location)
        ? itemData.location.map(item => item.name).join(' | ')
        : itemData.location;
    const truncatedLocationString = truncateWithEllipsis(locationString, 25);

    const logoImage = itemData && itemData.logo ? `${config.IMAGE_PATH}${itemData.logo}` : '';

    function formatNumber(value) {
        let str = value?.toString();
        let lastThree = str?.slice(-3);
        let otherNumbers = str?.slice(0, -3);
        if (otherNumbers !== '') {
            lastThree = ',' + lastThree;
        }
        let result = otherNumbers?.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        return result;
    }

    return (
        <>
            <div className='projectbox'>
                <div className='d-flex justify-content-between'>
                    <div className='projname_img'>
                        <img src={logoImage} width={'70px'} alt={itemData && itemData.title} />
                        <Link to={`/add-project?id=${itemData._id}`}>
                            <div className='projtxt'>
                                <h4>{itemData && itemData.title}</h4>
                                <div className='dflexbtwn'>
                                    <p> <IoLocationOutline />
                                        {truncatedLocationString && truncatedLocationString}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='project_ddn'>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                <IoSettingsOutline />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="py-2 prj_dropdown mt-2">
                                <Dropdown.Item>
                                    <div className="d-flex flex-row">
                                        <span onClick={() => setModalShowOne(true)}>Set Project Extension</span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <div className="d-flex flex-row">
                                        <span onClick={() => setModalShow(true)}>Set Budget</span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item href={`/employee-list?project_id=${itemData._id}&project_name=${itemData?.title}`}>
                                    <div className="d-flex flex-row">
                                       <span>Employee List</span>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <span className={itemData.status === 'Active' ? 'text-success' : 'text-danger'}>
                            {itemData.status}
                        </span>
                    </div>
                </div>
                <div className='dflexbtwn prj_time'>
                    <p> Project Duration </p>
                    <p> {itemData && itemData.duration} </p>
                </div>
                <div className='budgettypes'>
                    <div className='budgtbx'>
                        <p>{ formatNumber(itemData?.project_budget?.sanctioned) }</p>
                        <span>Sanctioned Budget</span>
                    </div>
                    <div className='budgtbx'>
                        <p>{ formatNumber(itemData?.project_budget?.utilized) }</p>
                        <span>Utilized Budget</span>
                    </div>
                    <div className='budgtbx'>
                        <p>{ formatNumber(itemData?.project_budget?.available) }</p>
                        <span>Available Budget</span>
                    </div>
                </div>
            </div>
            <BudgetModal show={modalShow} id={itemData._id} onHide={() => setModalShow(false)} />
            <ExtendDurationModal show={modalShowOne} id={itemData._id} onHide={() => setModalShowOne(false)} />
        </>
    );
};

export default ProjectBox;
