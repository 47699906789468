import React from "react";
import { MdCurrencyRupee } from "react-icons/md";

export default function Emp_salary({ data }) {
    
  const earningData = data.earning_data || {};
  const deductionData = data.deduction_data || {};

  const transportAllowances = earningData.transport_allowances || 0;
  const medicalAllowances = earningData.medical_allowances || 0;
  const childrenAllowances = earningData.children_allowances || 0;
  const specialAllowances = earningData.special_allowances || 0;
  const projectAllowances = earningData.project_allowances || 0;
  const chargeAllowances = earningData.charge_allowances || 0;
  const telephoneAllowances = earningData.telephone_allowances || 0;
  const perfmAllowances = earningData.perfm_allowances || 0;
  const medicalReimbursement = earningData.medical_reimbursement || 0;
  const employeepfAllowances = earningData.employee_pf || 0;
  const total = deductionData.total || 0;

  return (
    <>
      <div className="row my-3" data-aos="fade-in" data-aos-duration="3000">
        <div className="col-lg-12">
          <div className="sitecard pr-0">
            <div className="infobox">
              <h5>Earning</h5>
              <div className="infotext mx-50 mt-3">
                {data.basic_salary > 0 && (
                  <div className="infos">
                    <h6>Basic</h6>
                    <p className="d-flex flex-row gap-1 align-items-center">
                      <MdCurrencyRupee />
                      {data.basic_salary}
                    </p>
                  </div>
                )}
                {data.salary_hra > 0 && (
                  <div className="infos">
                    <h6>HRA</h6>
                    <p className="d-flex flex-row gap-1 align-items-center">
                      <MdCurrencyRupee />
                      {data.salary_hra}
                    </p>
                  </div>
                )}
                {transportAllowances > 0 && (
                  <div className="infos">
                    <h6>Transport</h6>
                    <p className="d-flex flex-row gap-1 align-items-center">
                      <MdCurrencyRupee />
                      {transportAllowances}
                    </p>
                  </div>
                )}
                {medicalAllowances > 0 && (
                  <div className="infos">
                    <h6>Medical</h6>
                    <p className="d-flex flex-row gap-1 align-items-center">
                      <MdCurrencyRupee />
                      {medicalAllowances}
                    </p>
                  </div>
                )}
                {specialAllowances > 0 && (
                  <div className="infos">
                    <h6>Special Allowances</h6>
                    <p className="d-flex flex-row gap-1 align-items-center">
                      <MdCurrencyRupee />
                      {specialAllowances}
                    </p>
                  </div>
                )}
                {employeepfAllowances > 0 && (
                  <div className="infos">
                    <h6>Employee PF</h6>
                    <p className="d-flex flex-row gap-1 align-items-center">
                      <MdCurrencyRupee />
                      {employeepfAllowances}
                    </p>
                  </div>
                )}
                {childrenAllowances > 0 && (
                  <div className="infos">
                    <h6>Children Allowances</h6>
                    <p className="d-flex flex-row gap-1 align-items-center">
                      <MdCurrencyRupee />
                      {childrenAllowances}
                    </p>
                  </div>
                )}
                {projectAllowances > 0 && (
                  <div className="infos">
                    <h6>Project Allowances</h6>
                    <p className="d-flex flex-row gap-1 align-items-center">
                      <MdCurrencyRupee />
                      {projectAllowances}
                    </p>
                  </div>
                )}
                {chargeAllowances > 0 && (
                  <div className="infos">
                    <h6>Charge Allowances</h6>
                    <p className="d-flex flex-row gap-1 align-items-center">
                      <MdCurrencyRupee />
                      {chargeAllowances}
                    </p>
                  </div>
                )}
                {telephoneAllowances > 0 && (
                  <div className="infos">
                    <h6>Telephone Allowances</h6>
                    <p className="d-flex flex-row gap-1 align-items-center">
                      <MdCurrencyRupee />
                      {telephoneAllowances}
                    </p>
                  </div>
                )}
                {perfmAllowances > 0 && (
                  <div className="infos">
                    <h6>Perform Allowances</h6>
                    <p className="d-flex flex-row gap-1 align-items-center">
                      <MdCurrencyRupee />
                      {perfmAllowances}
                    </p>
                  </div>
                )}
                {medicalReimbursement > 0 && (
                  <div className="infos">
                    <h6>Medical Reimbursement </h6>
                    <p className="d-flex flex-row gap-1 align-items-center">
                      <MdCurrencyRupee />
                      {medicalReimbursement}
                    </p>
                  </div>
                )}
                {data.salary_total > 0 && (
                  <div className="infos">
                    <h6>Total Earning</h6>
                    <p className="d-flex flex-row gap-1 align-items-center">
                      <MdCurrencyRupee />
                      {data.salary_total}
                    </p>
                  </div>
                )}
              </div>
              <h5>Deductions</h5>
              <div className="mx-50">
                {total > 0 && (
                  <div className="infos">
                    <h6>Employee PF (D)</h6>
                    <p className="d-flex flex-row gap-1 align-items-center">
                      <MdCurrencyRupee />
                      {total}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}