import React from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { CiCalendar } from "react-icons/ci";

export default function Emp_document({employeeDoc}) {
   
    return (
        <>
            <div className="row my-3" data-aos="fade-in" data-aos-duration="3000">
                <div className="col-lg-12">
                    <div className="sitecard pr-0 h-100 ps-0 pt-4">
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-column gap-2 mt-1 scroller-content w-100 smalldata infobox">
                                <Table className="candd_table">
                                    <thead>
                                        <tr>
                                            <th className="text-center fw-medium">S.No</th>
                                            <th className="text-center fw-medium">
                                                Document Name
                                            </th>
                                            <th className="text-center fw-medium">
                                                Category
                                            </th>
                                            <th className="text-center fw-medium">
                                                Attached Date
                                            </th>
                                            <th className="text-center fw-medium">
                                                Verified By
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {employeeDoc.length === 0 ? (
                                        <tr>
                                            <td colSpan="5">No documents available</td>
                                        </tr>
                                        ) : (
                                        employeeDoc.map((doc, index) => (
                                            <tr key={doc.id || index}>
                                            <td>{index + 1}</td>
                                            <td>{doc.doc_name || 'N/A'}</td>
                                            <td>{doc.doc_category || 'N/A'}</td>
                                            <td>{new Date(doc.add_date).toLocaleDateString()}</td>
                                            <td>{doc.name || 'N/A'}</td>
                                            </tr>
                                        ))
                                        )}
                                       
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
