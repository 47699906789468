import React, { useState, useEffect, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { CiCalendar } from "react-icons/ci";
import { getProjectList } from "./helper/Api_Helper";
import AsyncSelect from 'react-select/async';
import { useDispatch } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { FetchDesignationListForJob, FetchProjectListDropDown } from "../features/slices/ProjectListDropDown/ProjectListDropdownSlice";
import Select from "react-select";


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff',
    borderColor: state.isFocused ? '#D2C9FF' : state.isHovered ? '#80CBC4' : provided.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px #D2C9FF' : 'none',
    '&:hover': {
      borderColor: '#D2C9FF',
    },
    height: '44px',
  }),
  menu: (provided) => ({
    ...provided,
    borderTop: '1px solid #D2C9FF',
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #D2C9FF',
    color: state.isSelected ? '#fff' : '#000',
    backgroundColor: state.isSelected ? '#4CAF50' : state.isFocused ? '#80CBC4' : provided.backgroundColor,
    '&:hover': {
      backgroundColor: '#80CBC4',
      color: '#fff',
    },
  }),
};

export default function General_info({ formData, handleInputChange, designationData , projectListData }) {
  const [projectOptions, setProjectOptions] = useState([]);
  const [option, setOptions] = useState([]);
  const [projectBudgetEstimate, setProjectBudgetEstimate] = useState([]);
  const dispatch = useDispatch();
  const [projectList, setProjectList] = useState([]);
  const [DesignationValue , setDesignationValue] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);

  // const fetchProjectList = useCallback(async (inputValue) => {
  //   try {
  //     const response = await getProjectList(inputValue, "Active");
  //     if (response.data && Array.isArray(response.data)) {
  //       setProjectList(response.data?.map((key) => {
  //         return {
  //           value: key._id,
  //           label: key.title,
  //           budget_estimate_list: key.budget_estimate_list,
  //           location: key.location
  //         }
  //       }));

  //       handleInputChange({
  //         target: {
  //           name: "project_name",
  //           value: projectList.label,
  //         },
  //       });

  //       handleInputChange({
  //         target: {
  //           name: "project_id",
  //           value: projectList.value,
  //         },
  //       });

  //       localStorage.setItem("onBoardingLocation", JSON.stringify(projectList.location));
  //       setProjectBudgetEstimate(projectList.budget_estimate_list);

  //     } else {
  //       setProjectOptions([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching Project data", error);
  //     setProjectOptions([]);
  //   }
  // });


  useEffect(() => {
      if(projectListData){
        setProjectList(projectListData?.map((key) => {
          return {
            value: key._id,
            label: key.title,
            budget_estimate_list: key.budget_estimate_list,
            location: key.location
          }
        }));
      }
      //  add the Designation List Selected Designation ListData ->
      if(designationData?.length > 0){
        setFilteredOptions(designationData?.map((key) => {
          return {
            label: `${key.designation} (${key.no_of_positions} positions) - CTC: ${key.ctc}`,
            value: key.designation,
            ctc: key.ctc,
            id:key._id
          }
        }))
      }

      if (designationData?.length > 0 && formData.designation) {
        setDesignationValue(designationData?.map((key) => {
          return {
            label: `${key.designation} (${key.no_of_positions} positions) - CTC: ${key.ctc}`,
            value: key.designation,
            ctc: key.ctc,
            id:key._id
          }
        }).filter((data) => data.value === formData.designation))
      }
  } , [designationData, projectListData])


  /********************** Project List Dropdown ********************/
  const projectLoadOption = async (input) => {
    const result = await dispatch(FetchProjectListDropDown(input)).unwrap();
    return result;
  }
  const projectMenuOpen = async () => {
    const result = await dispatch(FetchProjectListDropDown('')).unwrap();
    setOptions(result);
  }
  const handleProjectChanges = (option) => {
    setProjectList(option)
    handleInputChange({
      target: {
        name: "project_name",
        value: option.label,
      },
    });

    handleInputChange({
      target: {
        name: "project_id",
        value: option.value,
      },
    });
    localStorage.setItem("onBoardingLocation", JSON.stringify(option.location));
    setDesignationValue([])
    setFilteredOptions(option.budget_estimate_list?.map((key) => {
      return {
        label: `${key.designation} (${key.no_of_positions} positions) - CTC: ${key.ctc}`,
        value: key.designation,
        ctc: key.ctc,
        id:key._id,
      }
    }))
  }

  /************** Old code changes *************/

  const handleDesignationChange = (option) => {
    setDesignationValue(option)
    handleInputChange({
      target: {
        name: "designation",
        value: option ? option.value : "",
      }
    });
    handleInputChange({
      target: {
        name: "ctc",
        value: option ? option.ctc : 0,
      }
    });
    handleInputChange({
      target: {
        name: "designation_id",
        value: option ? option.id : '',
      }
    });
  };

  const handleDesignationInputChange = (input) => {
    const filterOption = filteredOptions.filter(option => option.label.toLowerCase().includes(input.toLowerCase())
    ).slice(0, 10);
    setFilteredOptions(filterOption)
  }

  return (
    <>
      <div
        className="mt-5 gy-3 align-items-end"
        data-aos="fade-in"
        data-aos-duration="3000"
      >

        <div className="row ">
          <div className="col-6 d-flex flex-row gap-3 mb-4 ">
            <Form.Group
              className="position-relative"
              controlId="exampleForm.ControlInput13"
            >
              <Form.Label>Is this on-role or on-contract</Form.Label>
              <div className="col-12 d-flex flex-row gap-3">
                <Form.Check
                  type="radio"
                  label="On-role"
                  name="employee_type"
                  id="formHorizontalRadios1"
                  value="onRole"
                  checked={formData.employee_type === "onRole"}
                  onChange={handleInputChange}
                />
                <Form.Check
                  type="radio"
                  label="On-Consultant"
                  name="employee_type"
                  id="formHorizontalRadios2"
                  value="onContract"
                  checked={formData.employee_type === "onContract"}
                  onChange={handleInputChange}
                />
                {/* <Form.Check
                  type="radio"
                  label="Empanelled"
                  name="employee_type"
                  id="formHorizontalRadios3"
                  value="emPanelled"
                  checked={formData.employee_type === "emPanelled"}
                  onChange={handleInputChange}
                /> */}
              </div>
            </Form.Group>
          </div>
          <div className="col-6 projname mb-4 ">
            <Form.Group
              className="position-relative"
              controlId="exampleForm.ControlInput13"
            >
              <Form.Label>Employee Code (EC no.)</Form.Label>
              <Form.Control
                type="text"
                placeholder="100101000"
                name="employee_code"
                value={formData.employee_code || ""}
                onChange={handleInputChange}
              />

            </Form.Group>
          </div>
        </div>
        <div className="gy-3">
          <Form>
            <div className="row">
              <Form.Group
                className="mb-4 col-6 position-relative"
                controlId="employee_code"
              >
                <Form.Label>Project</Form.Label>
                <div className="d-flex flex-row gap-5">
                  <FormControl fullWidth>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      defaultValue={option}
                      loadOptions={projectLoadOption}
                      value={projectList}
                      onMenuOpen={projectMenuOpen}
                      placeholder="Select Project"
                      onChange={handleProjectChanges}
                      classNamePrefix="react-select"
                      styles={customStyles}
                    />
                  </FormControl>
                </div>
              </Form.Group>


              <Form.Group
                className="mb-4 col-6"
                controlId="exampleForm.ControlInput13"
              >
                <Form.Label>Designation</Form.Label>
                <div className="d-flex flex-row gap-5 text-start">
                  <FormControl fullWidth>
                    <Select
                      name="designation"
                      value={DesignationValue}
                      onChange={handleDesignationChange}
                      options={filteredOptions}
                      onInputChange={handleDesignationInputChange}
                      isSearchable={true} // Enable search functionality
                      placeholder="Choose Designation"
                      styles={customStyles}
                    />
                  </FormControl>
                </div>
              </Form.Group>

              <Form.Group className="mb-4 col-6" controlId="valid_till">
                <Form.Label>Valid Till</Form.Label>
                <div className="d-flex flex-row gap-5 datebox">
                  <Form.Control
                    type="date"
                    name="valid_till"
                    value={formData.valid_till || ""}
                    onChange={handleInputChange}
                  />
                  <CiCalendar />
                </div>
              </Form.Group>

              <Form.Group className="mb-4 col-6" controlId="name">
                <Form.Label>Name</Form.Label>
                <div className="d-flex flex-row gap-5">
                  <Form.Control
                    type="text"
                    placeholder="Anshul Awasthi"
                    name="name"
                    value={formData.name || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-4 col-6" controlId="father_name">
                <Form.Label>Father’s Name</Form.Label>
                <div className="d-flex flex-row gap-5">
                  <Form.Control
                    type="text"
                    placeholder="Srinivas Awasthi"
                    name="father_name"
                    value={formData.father_name || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-4 col-6" controlId="email">
                <Form.Label>Email ID</Form.Label>
                <div className="d-flex flex-row gap-5">
                  <Form.Control
                    type="email"
                    placeholder="xxx@gmail.com"
                    name="email"
                    value={formData.email || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-4 col-6" controlId="alt_email">
                <Form.Label>Alt Email ID</Form.Label>
                <div className="d-flex flex-row gap-5">
                  <Form.Control
                    type="text"
                    placeholder="xxxx@gmail.com"
                    name="alt_email"
                    value={formData.alt_email || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-4 col-6" controlId="mobile_no">
                <Form.Label>Mobile number</Form.Label>
                <div className="d-flex flex-row gap-5">
                  <Form.Control
                    type="text"
                    maxLength={10}
                    placeholder="+91-9794XXXX52"
                    name="mobile_no"
                    value={formData.mobile_no || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-4 col-6" controlId="aadhar">
                <Form.Label>Aadhaar number</Form.Label>
                <div className="d-flex flex-row gap-5">
                  <Form.Control
                    type="text"
                    maxLength={12}
                    placeholder="6622XXXX9284"
                    name="aadhaar_no"
                    value={formData.aadhaar_no || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-4 col-6" controlId="date_of_birth">
                <Form.Label>Date of Birth</Form.Label>
                <div className="d-flex flex-row gap-5 datebox">
                  <Form.Control
                    type="date"
                    name="date_of_birth"
                    value={formData.date_of_birth || ""}
                    onChange={handleInputChange}
                  />
                  <CiCalendar />
                </div>
              </Form.Group>

              <Form.Group className="mb-4 col-6" controlId="mobile_no">
                <Form.Label>Batch Id</Form.Label>
                <div className="d-flex flex-row gap-5">
                  <Form.Control
                    type="text"
                    maxLength={10}
                    placeholder="1xxxx"
                    name="batch_id"
                    value={formData.batch_id || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </Form.Group>
              
              <Form.Group className="mb-4 col-6" controlId="gender">
                <Form.Label>Gender</Form.Label>
                <div className="d-flex flex-row gap-5">
                  <Form.Check
                    type="radio"
                    label="Male"
                    name="gender"
                    id="formHorizontalRadios11"
                    value="Male"
                    checked={formData.gender === "Male"}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Female"
                    name="gender"
                    id="formHorizontalRadios22"
                    value="Female"
                    checked={formData.gender === "Female"}
                    onChange={handleInputChange}
                  />
                </div>
              </Form.Group>

              <Form.Group className="mb-4 col-6" controlId="marital_status">
                <Form.Label>Marital Status</Form.Label>
                <div className="d-flex flex-row gap-5">
                  <Form.Check
                    type="radio"
                    label="Single"
                    name="marital_status"
                    id="formHorizontalRadios21"
                    value="Single"
                    checked={formData.marital_status === "Single"}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="radio"
                    label="Married"
                    name="marital_status"
                    id="formHorizontalRadios32"
                    value="Married"
                    checked={formData.marital_status === "Married"}
                    onChange={handleInputChange}
                  />
                </div>
              </Form.Group>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
