import React, { useState, useEffect } from "react";
import GoBackButton from "./Goback";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { CiCalendar } from "react-icons/ci";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AllHeaders from "../features/partials/AllHeaders";
import { addEmployeeSalaryInfo, getEmployeeById } from "./helper/Api_Helper";

export default function Define() {

   // On Role base
   const [employeeData, setEmployeeData] = useState({});
   const [deductionData, setDeductionData] = useState({});
   const [earningData, setEarningData] = useState({});
   const [onContractData, setOnContractData] = useState({
    no_of_working_days: 0,
    consultation_fee: 0,
    mandate_deduction: 0,
    tds: 0,
    aadharPanLinked: 'No',
  });
  // Initial state for form data
  const [onEmpaneledData, setOnEmpaneledData] = useState({
    no_of_working_days: 0,
    budget_per_day: 0,
  });
  const [formData, setFormData] = useState({
    "basic_salary": 0,
    "total": 0,
    "salary_total": 0,
    "salary_da": 0,
    "salary_hra": 0,
    "transport_allowances": 0,
    "medical_allowances": 0,
    "children_allowances": 0,
    "special_allowances": 0,
    "project_allowances": 0,
    "charge_allowances": 0,
    "substn_allowances": 0,
    "telephone_allowances": 0,
    "pda": 0,
    "other_allowances": 0,
    "perfm_allowances": 0,
    "medical_reimbursement": 0,
    "other_reimbursement": 0,
    "employee_pf": 0,
    "pf_amount": 0,
    "salary_adv": 0,
    "fest_all": 0,
    "other": 0,
    "salary_effective_date": 0,
});
  // const [formData, setFormData] = useState(() => {
  //   // Load initial data from localStorage or set defaults
  //   const savedData = localStorage.getItem("formData");
  //   return savedData ? JSON.parse(savedData) : {};
  // });
  useEffect(() => {
    const fetchDesignations = async () => {
      try {
        const myId = localStorage.getItem("onBoardingId");
        if (!myId) {
          console.error("No onboarding ID found in localStorage");
          return;
        }
  
        const obj1 = { _id: myId };
        const response = await getEmployeeById(obj1);
  
        if (response && response.data && response.status) {
          // Destructure data and set default values
          const { deduction_data = {}, earning_data = {} } = response.data;
          
          setEmployeeData(response.data); // Extract the data object
          setDeductionData(deduction_data); // Default to empty object if undefined
          setEarningData(earning_data); // Default to empty object if undefined
        } else {
          // Set empty states if response is invalid
          setEmployeeData({});
          setDeductionData({});
          setEarningData({});
        }
      } catch (error) {
        console.error("Error fetching designation data", error);
        setEmployeeData({});
        setDeductionData({});
        setEarningData({});
      }
    };
  
    fetchDesignations(); // Call the function to fetch data when component mounts
  }, []);
  //  console.log(deductionData)
  //  console.log(earningData)
   useEffect(() => {

    const formatDateToYYYYMMDD = (dateStr) => {
      if (!dateStr) return '';
  
      // Parse the date string
      const date = new Date(dateStr);
  
      // Check if the date is valid
      if (isNaN(date.getTime())) return '';
  
      // Format the date to YYYY-MM-DD
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
  
      return `${year}-${month}-${day}`;
    };
    // Update onRoll when deductionData or earningData changes
    setFormData((prevData) => ({
      ...prevData,
          "basic_salary": employeeData.basic_salary || 0,
          "total": employeeData.total || 0,
          "salary_total": employeeData.salary_total || 0,
          "salary_da": employeeData.salary_da || 0,
          "salary_hra": employeeData.salary_hra || 0,
          "salary_effective_date": formatDateToYYYYMMDD(employeeData.salary_effective_date) || 0,
          "transport_allowances" : earningData.transport_allowances || 0,
          "medical_allowances" : earningData.medical_allowances || 0,
          "children_allowances" : earningData.children_allowances || 0,
          "special_allowances" : earningData.special_allowances || 0,
          "project_allowances" : earningData.project_allowances || 0,
          "charge_allowances" : earningData.charge_allowances || 0,
          "substn_allowances" : earningData.substn_allowances || 0,
          "telephone_allowances" : earningData.telephone_allowances || 0,
          "pda" : earningData.pda || 0,
          "other_allowances" : earningData.other_allowances || 0,
          "perfm_allowances" : earningData.perfm_allowances || 0,
          "medical_reimbursement" : earningData.medical_reimbursement || 0,
          "other_reimbursement" : earningData.other_reimbursement || 0,
          "employee_pf" : earningData.employee_pf || 0,
          "pf_amount" : deductionData.pf_amount || 0,
          "salary_adv"  : deductionData.salary_adv || 0,
          "fest_all"  : deductionData.fest_all || 0,
          "other"  : deductionData.other || 0,
          "total"  : deductionData.total || 0,
    }));
  }, [deductionData, earningData]);
   useEffect(() => {
    // Update emPanelled when earningData changes
    setOnEmpaneledData((prevData) => ({
      ...prevData,
      no_of_working_days: earningData.no_of_working_days || 0,
      budget_per_day: earningData.budget_per_day || 0,
     
    }));
  }, [earningData]);

   useEffect(() => {
    // Update onContractData when deductionData or earningData changes
    setOnContractData((prevData) => ({
      ...prevData,
      no_of_working_days: earningData.no_of_working_days || 0,
      consultation_fee: earningData.consultation_fee || 0,
      mandate_deduction: deductionData.mandate_deduction || 0,
      tds: deductionData.tds || 0,
      aadharPanLinked: deductionData.aadharPanLinked || 'No',
    }));
  }, [deductionData, earningData]);

 
  // Handle input changes for text fields
  const handleonContractInputChange = (e) => {
    const { name, value } = e.target;
    setOnContractData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle radio button changes
  const handleRadioChange = (e) => {
    setOnContractData((prevData) => ({
      ...prevData,
      aadharPanLinked: e.target.value,
    }));
  };

  const saveContractData = async () => {
    try {
      const myId = localStorage.getItem("onBoardingId");
      const obj1 = { _id: myId };
      const payload = { ...obj1, ...onContractData, employee_type: employeeData.employee_type };
      // Save data to the API before proceeding to the next step
      const response = await addEmployeeSalaryInfo(payload);
      if (response.status) {
        toast.success(response.message);
        navigate("/people-profile ");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error saving data:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };
  // Contract base
  

  // Handler for input changes
  const handleonEmpaneledInputChange = (event) => {
    const { name, value } = event.target;
    setOnEmpaneledData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const saveEmpaneledData = async () => {
    try {
      const myId = localStorage.getItem("onBoardingId");
      const obj1 = { _id: myId };
      const payload = { ...obj1, ...onEmpaneledData, employee_type: employeeData.employee_type };
      // Save data to the API before proceeding to the next step
      const response = await addEmployeeSalaryInfo(payload);
      if (response.status) {
        toast.success(response.message);
        navigate("/people-profile ");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error saving data:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };
  // Empaneled base Employee
 
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("first");
  const calculateTotal = (formData) => {
    const pf_amount = parseFloat(formData.pf_amount) || 0;
    const salary_adv = parseFloat(formData.salary_adv) || 0;
    const fest_all = parseFloat(formData.fest_all) || 0;
    const other = parseFloat(formData.other) || 0;

    return pf_amount + salary_adv + fest_all + other;
  };

  const calculateTotalSalary = (data) => {
    // Convert all values to numbers and sum them up
    const total = [
      "basic_salary",
      "salary_hra",
      "transport_allowances",
      "medical_allowances",
      "children_allowances",
      "special_allowances",
      "project_allowances",
      "charge_allowances",
      "salary_da",
      "substn_allowances",
      "telephone_allowances",
      "pda",
      "other_allowances",
      "perfm_allowances",
      "medical_reimbursement",
      "other_reimbursement",
      "employee_pf",
    ].reduce((acc, key) => acc + (parseFloat(data[key]) || 0), 0);

    // Update the total salary in the formData state
    setFormData((prevData) => ({
      ...prevData,
      salary_total: total.toFixed(2), // Format to 2 decimal places
    }));
  };
  const changeTab = () => {
    setActiveTab("second");
  };

  // On Role base
  

  useEffect(() => {
    // Save formData to localStorage whenever it changes
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [FormData]);
  const resetForm = () => {
    // Clear formData and localStorage
    setFormData({});
    localStorage.removeItem("formData");
    // Optionally, show a success message
    toast.success("Form has been reset.");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const updatedFormData = {
      ...formData,
      [name]: value,
    };

    // Calculate total and update form data
    updatedFormData.total = calculateTotal(updatedFormData);

    setFormData(updatedFormData);
    // Recalculate total salary
    calculateTotalSalary({
      ...formData,
      [name]: value,
    });
  };

  const saveData = async () => {
    try {
      const myId = localStorage.getItem("onBoardingId");
      const obj1 = { _id: myId };
      const payload = { ...obj1, ...formData, employee_type: employeeData.employee_type };
      // Save data to the API before proceeding to the next step
      const response = await addEmployeeSalaryInfo(payload);
      if (response.status) {
        toast.success(response.message);
        navigate("/people-profile ");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error saving data:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };
  // On Role base

  return (
    <>
      <AllHeaders />

      {employeeData.employee_type === "onRole" && (
        <div className="maincontent">
          <div
            className="container"
            data-aos="fade-in"
            data-aos-duration="3000"
          >
            <GoBackButton />
            <div className="d-flex justify-content-between read-btn reload-btn">
              <h2>Define Salary Structure - On role</h2>
              <button
                onClick={resetForm}
                className="btn d-flex flex-row justify-content-center align-items-center gap-2 w-small py-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <rect
                    width="16"
                    height="16"
                    transform="translate(0.5)"
                    fill="#BFE7FA"
                  />
                  <path
                    d="M9.5 14C10.6867 14 11.8467 13.6481 12.8334 12.9888C13.8201 12.3295 14.5892 11.3925 15.0433 10.2961C15.4974 9.19975 15.6162 7.99335 15.3847 6.82946C15.1532 5.66558 14.5818 4.59648 13.7426 3.75736C12.9035 2.91825 11.8344 2.3468 10.6705 2.11529C9.50666 1.88378 8.30026 2.0026 7.2039 2.45673C6.10754 2.91085 5.17047 3.67989 4.51118 4.66658C3.85189 5.65328 3.5 6.81331 3.5 8V11.1L1.7 9.3L1 10L4 13L7 10L6.3 9.3L4.5 11.1V8C4.5 7.0111 4.79324 6.0444 5.34265 5.22215C5.89206 4.39991 6.67295 3.75904 7.58658 3.38061C8.50021 3.00217 9.50555 2.90315 10.4755 3.09608C11.4454 3.289 12.3363 3.76521 13.0355 4.46447C13.7348 5.16373 14.211 6.05465 14.4039 7.02455C14.5969 7.99446 14.4978 8.99979 14.1194 9.91342C13.741 10.8271 13.1001 11.6079 12.2779 12.1574C11.4556 12.7068 10.4889 13 9.5 13V14Z"
                    fill="#155674"
                  />
                </svg>
                Reset
              </button>
            </div>
            <div className="mt-3">
              <div className="postedjobs">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="first"
                  fill
                >
                  <Nav
                    variant="pills"
                    className="flex-row border-full d-flex justify-content-between align-items-end"
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                  >
                    <div className="d-flex flex-row">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          className={
                            activeTab === "first" ? "active " : "unactive "
                          }
                        >
                          Earning
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          className={
                            activeTab === "second" ? "active " : "unactive"
                          }
                        >
                          Deductions
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                  </Nav>
                  <Tab.Content
                    className="contere hrforms"
                    activeKey={activeTab}
                  >
                    <Tab.Pane
                      eventKey="first"
                      className={
                        activeTab === "first" ? "active show" : "active d-none"
                      }
                    >
                      <div className="sitecard my-3">
                        <div className="">
                          <Form className="d-flex flex-row gap-3 mb-4">
                            <Form.Group
                              controlId="exampleForm.ControlInput1"
                              className="w-100"
                            >
                              <Form.Label>Basic</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                              
                                <Form.Control
                                  type="text"
                                  className="w-100 ps-4 ms-1 form-control fs-50"
                                  name="basic_salary"
                                  value={formData.basic_salary || ""}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: { name: "basic_salary", value },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              controlId="exampleForm.ControlInput1"
                              className="w-100"
                            >
                              <Form.Label>DA</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-100 ps-4 ms-1 form-control fs-50"
                                  name="salary_da"
                                  value={formData.salary_da || ""}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: { name: "salary_da", value },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group
                              controlId="exampleForm.ControlInput1"
                              className="w-100 mb-3"
                            >
                              <Form.Label>HRA</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative w-100">
                                <Form.Control
                                  type="text"
                                  className="w-100 ps-4 ms-1 form-control fs-50"
                                  name="salary_hra"
                                  value={formData.salary_hra || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: { name: "salary_hra", value },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </Form>
                          <h4>Allowance</h4>
                        </div>
                        <div className="row gx-5">
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Transport Allowances</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="transport_allowances"
                                  value={formData.transport_allowances || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: {
                                          name: "transport_allowances",
                                          value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Medical Allowances</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="medical_allowances"
                                  value={formData.medical_allowances || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: {
                                          name: "medical_allowances",
                                          value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Children Allowances</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="children_allowances"
                                  value={formData.children_allowances || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: {
                                          name: "children_allowances",
                                          value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Special Allowances</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="special_allowances"
                                  value={formData.special_allowances || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: {
                                          name: "special_allowances",
                                          value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Project Allowances</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="project_allowances"
                                  value={formData.project_allowances || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: {
                                          name: "project_allowances",
                                          value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Charge Allowances</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="charge_allowances"
                                  value={formData.charge_allowances || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: {
                                          name: "charge_allowances",
                                          value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>SUBSTN Allowances</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="substn_allowances"
                                  value={formData.substn_allowances || ""}
                                  onChange={handleInputChange}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Telephone Allowances</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="telephone_allowances"
                                  value={formData.telephone_allowances || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: {
                                          name: "telephone_allowances",
                                          value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>PDA</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="pda"
                                  value={formData.pda || ""}
                                  //  onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: { name: "pda", value },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Other Allowance</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="other_allowances"
                                  value={formData.other_allowances || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: {
                                          name: "other_allowances",
                                          value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>PERFM Allowances</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="perfm_allowances"
                                  value={formData.perfm_allowances || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: {
                                          name: "perfm_allowances",
                                          value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Medical Reimbursement</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="medical_reimbursement"
                                  value={formData.medical_reimbursement || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: {
                                          name: "medical_reimbursement",
                                          value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Other Reimbursement</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="other_reimbursement"
                                  value={formData.other_reimbursement || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: {
                                          name: "other_reimbursement",
                                          value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Employer PF</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="employee_pf"
                                  value={formData.employee_pf || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: { name: "employee_pf", value },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3 mb-3 align-items-end">
                          <div className="col-lg-5">
                            <Form.Group
                              controlId="exampleForm.ControlInput1"
                              className="w-100"
                            >
                              <Form.Label>Total</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-100 ps-4 ms-1 form-control fs-50"
                                  name="salary_total"
                                  value={formData.salary_total || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: { name: "salary_total", value },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="read-btn w-large w-100">
                            <button className="btn" onClick={changeTab}>
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="second"
                      className={
                        activeTab === "second" ? "active show" : "active d-none"
                      }
                    >
                      <div className="sitecard my-3">
                        <div className="">
                          <Form.Label>Deductions</Form.Label>
                        </div>
                        <div className="row gx-5">
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Employer PF (D)</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="pf_amount"
                                  value={formData.pf_amount || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: { name: "pf_amount", value },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Salary Adv</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="salary_adv"
                                  value={formData.salary_adv || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: { name: "salary_adv", value },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>FEST All</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="fest_all"
                                  value={formData.fest_all || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: { name: "fest_all", value },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="col-lg-3">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Other</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-smaller ps-4 ms-1 form-control fs-50"
                                  name="other"
                                  value={formData.other || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: { name: "other", value },
                                      });
                                    }
                                  }} 
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <Form.Group
                              controlId="exampleForm.ControlInput1"
                              className="mb-3 w-100 pe-4"
                            >
                              <Form.Label>Total</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-100 ps-4 ms-1 form-control fs-50"
                                  name="total"
                                  value={formData.total || ""}
                                  // onChange={handleInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleInputChange({
                                        target: { name: "total", value },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <Form.Group
                              controlId="exampleForm.ControlInput1"
                              className="mb-3 w-100 "
                            >
                              <Form.Label>Add Salary Effective Date</Form.Label>
                              <div className="d-flex flex-row gap-5 datebox">
                                <Form.Control
                                  type="date"
                                  className="w-100 ps-4 ms-1 form-control fs-6"
                                  name="salary_effective_date"
                                  value={formData.salary_effective_date || ""}
                                  onChange={handleInputChange}
                                />
                                <CiCalendar />
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end mt-4 align-items-end">
                          <div className="read-btn w-large w-100">
                            {/* <Link to="/people-profile"> */}
                            <button onClick={saveData} className="btn">
                              Create Appointment Letter
                            </button>
                            {/* </Link> */}
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      )}

      {employeeData.employee_type === "onContract" && (
        <div className="maincontent">
          <div
            className="container"
            data-aos="fade-in"
            data-aos-duration="3000"
          >
            <GoBackButton />
            <div className="d-flex justify-content-between read-btn reload-btn">
              <div class="hrhdng">
                <h2>Define Salary Structure - On contract</h2>
              </div>
              <button className="btn d-flex flex-row justify-content-center align-items-center gap-2 w-small py-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <rect
                    width="16"
                    height="16"
                    transform="translate(0.5)"
                    fill="#BFE7FA"
                  />
                  <path
                    d="M9.5 14C10.6867 14 11.8467 13.6481 12.8334 12.9888C13.8201 12.3295 14.5892 11.3925 15.0433 10.2961C15.4974 9.19975 15.6162 7.99335 15.3847 6.82946C15.1532 5.66558 14.5818 4.59648 13.7426 3.75736C12.9035 2.91825 11.8344 2.3468 10.6705 2.11529C9.50666 1.88378 8.30026 2.0026 7.2039 2.45673C6.10754 2.91085 5.17047 3.67989 4.51118 4.66658C3.85189 5.65328 3.5 6.81331 3.5 8V11.1L1.7 9.3L1 10L4 13L7 10L6.3 9.3L4.5 11.1V8C4.5 7.0111 4.79324 6.0444 5.34265 5.22215C5.89206 4.39991 6.67295 3.75904 7.58658 3.38061C8.50021 3.00217 9.50555 2.90315 10.4755 3.09608C11.4454 3.289 12.3363 3.76521 13.0355 4.46447C13.7348 5.16373 14.211 6.05465 14.4039 7.02455C14.5969 7.99446 14.4978 8.99979 14.1194 9.91342C13.741 10.8271 13.1001 11.6079 12.2779 12.1574C11.4556 12.7068 10.4889 13 9.5 13V14Z"
                    fill="#155674"
                  />
                </svg>
                Reset
              </button>
            </div>
            <div className="mt-3">
              <div className="postedjobs hrforms">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="first"
                  fill
                >
                  <Nav
                    variant="pills"
                    className="flex-row border-full d-flex justify-content-between align-items-end"
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                  >
                    <div className="d-flex flex-row">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          className={
                            activeTab === "first" ? "active " : "unactive "
                          }
                        >
                          Earning
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          className={
                            activeTab === "second" ? "active " : "unactive"
                          }
                        >
                          Deductions
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                  </Nav>
                  <Tab.Content className="contere" activeKey={activeTab}>
                    <Tab.Pane
                      eventKey="first"
                      className={
                        activeTab === "first" ? "active show" : "active d-none"
                      }
                    >
                      <div className="sitecard my-3">
                        <div className="mx-1000">
                          <Form className="d-flex flex-row gap-3 mb-4">
                            <Form.Group
                              controlId="exampleForm.ControlInput1"
                              className="w-100"
                            >
                              <Form.Label>Number of Days (Working)</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-100 ps-4 ms-1 form-control fs-5"
                                  name="no_of_working_days"
                                  value={
                                    onContractData.no_of_working_days || earningData.no_of_working_days || ''
                                  }
                                  // onChange={handleonContractInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleonContractInputChange({
                                        target: {
                                          name: "no_of_working_days",
                                          value,
                                        },
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </Form.Group>
                            <Form.Group
                              controlId="exampleForm.ControlInput1"
                              className="w-100"
                            >
                              <Form.Label>Consultation Fee / CTO</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative w-100">
                                <Form.Control
                                  type="text"
                                  className="w-100 ps-4 ms-1 form-control fs-5"
                                  name="consultation_fee"
                                  value={onContractData.consultation_fee || earningData.consultation_fee || ''}
                                  // onChange={handleonContractInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleonContractInputChange({
                                        target: {
                                          name: "consultation_fee",
                                          value,
                                        },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </Form>
                        </div>

                        <div className="d-flex justify-content-end mt-5 align-items-end mx-1000">
                          <div className="read-btn w-large w-100">
                            <button className="btn" onClick={changeTab}>
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="second"
                      className={
                        activeTab === "second" ? "active show" : "active d-none"
                      }
                    >
                      <div className="sitecard my-3">
                        <div className="mx-1000">
                          <Form.Label>Deductions</Form.Label>
                        </div>
                        <div className="row gx-5 mx-1000">
                          <div className="col-lg-6">
                            <div className="d-flex flex-row gap-3 ">
                              <Form className="d-flex flex-column gap-y  justify-content-between">
                                <div className="d-flex flex-row gap-4">
                                  <Form.Label>Aadhar Pan linked</Form.Label>
                                  <Form.Check
                                    type="radio"
                                    label="Yes"
                                    name="formHorizontalRadios"
                                    id="formHorizontalRadios1"
                                    value="Yes"
                                    checked={
                                      ( onContractData.aadharPanLinked === "Yes" ||
                                        deductionData.aadharPanLinked === "Yes") 
                                    }
                                    onChange={handleRadioChange}
                                  />
                                  <Form.Check
                                    type="radio"
                                    label="No"
                                    name="formHorizontalRadios"
                                    id="formHorizontalRadios2"
                                    value="No"
                                    checked={
                                      ( onContractData.aadharPanLinked === "No" ||
                                        deductionData.aadharPanLinked === "No") 
                                    }
                                    onChange={handleRadioChange}
                                  />
                                </div>
                                <div className="d-flex flex-row gap-5 mt-2">
                                  <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>Mandate Deduction</Form.Label>
                                    <div className="d-flex flex-row gap-5 position-relative">
                                      <Form.Control
                                        type="text"
                                        className="w-smaller ps-4 ms-1 form-control fs-5"
                                        name="mandate_deduction"
                                        value={
                                          onContractData.mandate_deduction || deductionData.mandate_deduction || ''
                                        }
                                        // onChange={handleonContractInputChange}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const regex = /^\d*$/; // Allows only digits
                                          if (regex.test(value)) {
                                            handleonContractInputChange({
                                              target: {
                                                name: "mandate_deduction",
                                                value,
                                              },
                                            });
                                          }
                                        }}
                                      />
                                      <div className="rp_icon">
                                        <LiaRupeeSignSolid size={"16px"} />
                                      </div>
                                    </div>
                                  </Form.Group>

                                  <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>TDS</Form.Label>
                                    <div className="d-flex flex-row gap-5 position-relative">
                                      <Form.Control
                                        type="text"
                                        className="w-smaller ps-4 ms-1 form-control fs-5"
                                        name="tds"
                                        value={onContractData.tds || deductionData.tds || ''}
                                        //  onChange={handleonContractInputChange}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          const regex = /^\d*$/; // Allows only digits
                                          if (regex.test(value)) {
                                            handleonContractInputChange({
                                              target: { name: "tds", value },
                                            });
                                          }
                                        }}
                                      />
                                      <div className="rp_icon">
                                        <LiaRupeeSignSolid size={"16px"} />
                                      </div>
                                    </div>
                                  </Form.Group>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end mt-4 align-items-end mx-1000">
                          <div className="read-btn w-large w-100">
                            <button onClick={saveContractData} className="btn">
                              Create Appointment Letter
                            </button>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      )}
      {employeeData.employee_type === "emPanelled" && (
        <div className="maincontent">
          <div
            className="container"
            data-aos="fade-in"
            data-aos-duration="3000"
          >
            <GoBackButton />
            <div className="d-flex justify-content-between read-btn reload-btn">
              <h2 className="fs-3">Define Salary Structure - Empaneled</h2>
              <button className="btn d-flex flex-row justify-content-center align-items-center gap-2 w-small py-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <rect
                    width="16"
                    height="16"
                    transform="translate(0.5)"
                    fill="#BFE7FA"
                  />
                  <path
                    d="M9.5 14C10.6867 14 11.8467 13.6481 12.8334 12.9888C13.8201 12.3295 14.5892 11.3925 15.0433 10.2961C15.4974 9.19975 15.6162 7.99335 15.3847 6.82946C15.1532 5.66558 14.5818 4.59648 13.7426 3.75736C12.9035 2.91825 11.8344 2.3468 10.6705 2.11529C9.50666 1.88378 8.30026 2.0026 7.2039 2.45673C6.10754 2.91085 5.17047 3.67989 4.51118 4.66658C3.85189 5.65328 3.5 6.81331 3.5 8V11.1L1.7 9.3L1 10L4 13L7 10L6.3 9.3L4.5 11.1V8C4.5 7.0111 4.79324 6.0444 5.34265 5.22215C5.89206 4.39991 6.67295 3.75904 7.58658 3.38061C8.50021 3.00217 9.50555 2.90315 10.4755 3.09608C11.4454 3.289 12.3363 3.76521 13.0355 4.46447C13.7348 5.16373 14.211 6.05465 14.4039 7.02455C14.5969 7.99446 14.4978 8.99979 14.1194 9.91342C13.741 10.8271 13.1001 11.6079 12.2779 12.1574C11.4556 12.7068 10.4889 13 9.5 13V14Z"
                    fill="#155674"
                  />
                </svg>
                Reset
              </button>
            </div>
            <div className="mt-3">
              <div className="postedjobs hrforms">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="first"
                  fill
                >
                  <Nav
                    variant="pills"
                    className="flex-row border-full d-flex justify-content-between align-items-end"
                    activeKey={activeTab}
                  >
                    <div className="d-flex flex-row">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          className={
                            activeTab === "first" ? "active " : "unactive "
                          }
                        >
                          Earning
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                  </Nav>
                  <Tab.Content className="contere" activeKey={activeTab}>
                    <Tab.Pane
                      eventKey="first"
                      className={
                        activeTab === "first" ? "active show" : "active d-none"
                      }
                    >
                      <div className="sitecard my-3">
                        <div className="mx-1000">
                          <Form className="d-flex flex-row gap-3 mb-4">
                            <Form.Group
                              controlId="exampleForm.ControlInput1"
                              className="w-100"
                            >
                              <Form.Label>Number of Days (Working)</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative">
                                <Form.Control
                                  type="text"
                                  className="w-100 ps-4 ms-1 form-control fs-5"
                                  name="no_of_working_days"
                                  value={
                                    onEmpaneledData.no_of_working_days || earningData.no_of_working_days || ''
                                  }
                                  // onChange={handleonEmpaneledInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleonEmpaneledInputChange({
                                        target: {
                                          name: "no_of_working_days",
                                          value,
                                        },
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </Form.Group>
                            <Form.Group
                              controlId="exampleForm.ControlInput1"
                              className="w-100"
                            >
                              <Form.Label>Budget Per Day</Form.Label>
                              <div className="d-flex flex-row gap-5 position-relative w-100">
                                <Form.Control
                                  type="text"
                                  className="w-100 ps-4 ms-1 form-control fs-5"
                                  name="budget_per_day"
                                  value={onEmpaneledData.budget_per_day || earningData.budget_per_day || ''}
                                  // onChange={handleonEmpaneledInputChange}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^\d*$/; // Allows only digits
                                    if (regex.test(value)) {
                                      handleonEmpaneledInputChange({
                                        target: { name: "budget_per_day", value },
                                      });
                                    }
                                  }}
                                />
                                <div className="rp_icon">
                                  <LiaRupeeSignSolid size={"16px"} />
                                </div>
                              </div>
                            </Form.Group>
                          </Form>
                        </div>

                        <div className="d-flex justify-content-end mt-5 align-items-end mx-1000">
                          <div className="read-btn w-large w-100">
                            <button onClick={saveEmpaneledData} className="btn">
                              Create Appointment Letter
                            </button>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
