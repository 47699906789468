import React, { useState } from "react";
import { LuLayoutDashboard } from "react-icons/lu";
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import { MdOutlineEventNote } from "react-icons/md";
import { GoPeople } from "react-icons/go";
import { LuUserCheck } from "react-icons/lu";
import { MdOutlinePayments } from "react-icons/md";
import { LuFileKey2 } from "react-icons/lu";
import { LuSettings2 } from "react-icons/lu";
import { LuLogOut } from "react-icons/lu";
import { PiGraph } from "react-icons/pi";
import { FaArrowTrendUp } from "react-icons/fa6";
import { LuArrowUpSquare } from "react-icons/lu";
import { GrDocumentExcel } from "react-icons/gr";
import { MdManageAccounts } from "react-icons/md";
import { MdArtTrack } from "react-icons/md";
import { MdSpatialTracking } from "react-icons/md";
import { GoProjectRoadmap } from "react-icons/go";
import { IoMdAnalytics } from "react-icons/io";



import { FaLocationDot } from "react-icons/fa6";
import { TbTimeDurationOff } from "react-icons/tb";
import { GiDuration } from "react-icons/gi";
import { FaPersonBreastfeeding } from "react-icons/fa6";
import { MdSpatialAudioOff } from "react-icons/md";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { MdRealEstateAgent } from "react-icons/md";
import { SiPrivatedivision } from "react-icons/si";
import { CgPathDivide } from "react-icons/cg";
import { CiBank } from "react-icons/ci";
import { PiTrainRegionalFill } from "react-icons/pi";
import { CgDisplayGrid } from "react-icons/cg";
import { MdSensorOccupied } from "react-icons/md";
import { FaTags } from "react-icons/fa6";
import { MdCastForEducation } from "react-icons/md";
import { MdAddLocationAlt } from "react-icons/md";
// import { TbTimeDurationOff } from "react-icons/tb";
import { TbBrandMastercard } from "react-icons/tb";
import { FaBehanceSquare } from "react-icons/fa";
import { MdHolidayVillage } from "react-icons/md";
import { SiPayloadcms } from "react-icons/si";
import { FaSackDollar } from "react-icons/fa6";
import { FaCalendarDays } from "react-icons/fa6";
import { FaExchangeAlt } from "react-icons/fa";
import { IoIosPersonAdd } from "react-icons/io";







import config from "../../config/config";


import { Link } from 'react-router-dom';

// import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi/";
import MenuIcon from '@mui/icons-material/Menu';

import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

function Sidebars() {
    //const { collapseSidebar } = useProSidebar();
    const [collapsed, setCollapsed] = useState(true);

    const [toggled, setToggled] = useState(false);

    const handleCollapsedChange = () => {
        setCollapsed(!collapsed);
    };
    const handleToggleSidebar = (value) => {
        setToggled(value);
    };

    return (
        <>
            <Sidebar className={`sitesidebar app ${toggled ? "toggled" : ""}`} style={{ height: "100%", position: "fixed" }} collapsed={collapsed} toggled={toggled}
                handleToggleSidebar={handleToggleSidebar} handleCollapsedChange={handleCollapsedChange}>
                <main>
                    <Menu className="sidelogobox">
                        {collapsed ? (<MenuItem icon={<MenuIcon />} onClick={handleCollapsedChange}></MenuItem>) : (
                            <MenuItem prefix={<MenuIcon />} onClick={handleCollapsedChange} >
                                <div className="dashlogo">
                                    <img src={config.LOGO_PATH} alt={config.COMPANY_NAME} />
                                </div>
                            </MenuItem>
                        )}
                    </Menu>

                    <Menu className="sidemenus noline">

                        <SubMenu className="active" icon={<LuLayoutDashboard />} component={<Link to="/dashboard" />} label="Dashboard">
                            <MenuItem className="sub_active" icon={<LuLayoutDashboard />} component={<Link to="/dashboard" />}> Dashboard </MenuItem>
                        </SubMenu>
                        <div className="menulables">
                            <p>Master</p>
                        </div>
                        <MenuItem icon={<TokenOutlinedIcon />} component={<Link to="/projects" />} >  Project </MenuItem>
                        <div className="menulables">
                            <p>ATS</p>
                        </div>
                        <MenuItem icon={<MdOutlineEventNote />} component={<Link to="/ats" />} >  Application Tracking System</MenuItem>
                        <MenuItem icon={<MdManageAccounts />} component={<Link to="/assessment" />} >  ManageAssessment</MenuItem>
                        <div className="menulables">
                            <p>Analytic Trackers</p>
                        </div>
                        <MenuItem className="" icon={<PiGraph />} component={<Link to="/analytics" />}> Analytics </MenuItem>
                        <MenuItem className="" icon={<MdSpatialTracking />} component={<Link to="/employementtracker" />}> Employment Tracker </MenuItem>
                        <MenuItem className="" icon={<MdOutlineEventNote />} component={<Link to="/ats" />}> Job (ATS) Tracker </MenuItem>
                        <MenuItem className="" icon={<GoProjectRoadmap />} component={<Link to="/project-tracker" />}> Project Tracker </MenuItem>
                        <MenuItem className="" icon={<GoProjectRoadmap />} component={<Link to="/employee-alumni-tracker" />}> Employee Alumni Tracker </MenuItem>
                        {/* <MenuItem className="sub_active" icon={<IoMdAnalytics />} component={<Link to="/employementtracker" />}> Analytic </MenuItem> */}
                        <div className="menulables">
                            <p>Manpower Requisition Form</p>
                        </div>
                            <MenuItem className="" icon={<IoIosPersonAdd />} component={<Link to="/manpower-acquisition" />}> Add From  </MenuItem>
                            <MenuItem className="" icon={<MdOutlineEventNote />} component={<Link to="/employee-appraisal" />}> List of Froms </MenuItem>        
                        <div className="menulables">
                            <p>People</p>
                        </div>
                        <SubMenu className="" icon={<GoPeople />} label="People">
                            <MenuItem className="sub_active" icon={<FaArrowTrendUp />} component={<Link to="/employee-extension" />}> Extension </MenuItem>
                            <MenuItem className="sub_active" icon={<LuArrowUpSquare />} component={<Link to="/employee-appraisal" />}> Appraisal </MenuItem>
                            <MenuItem className="sub_active" icon={<GrDocumentExcel />} component={<Link to="/contract-closure" />}> Full & Final </MenuItem>
                            <MenuItem className="sub_active" icon={<GoPeople />} component={<Link to="/employementtracker" />}> People </MenuItem>
                        </SubMenu>
                        {/* Tracker menu */}
                        <MenuItem icon={<LuUserCheck />} component={<Link to="/attendance-index" />} > Attendance</MenuItem>
                        <MenuItem icon={<MdOutlinePayments />} component={<Link to="/payroll" />} > Payroll</MenuItem>
                        <MenuItem icon={<LuFileKey2 />} component={<Link to="/" />} >  Policies</MenuItem>
                        <MenuItem icon={<LuSettings2 />} component={<Link to="/" />} > Setting</MenuItem>
                        <MenuItem icon={<LuLogOut />} component={<Link to="/" />} > Logout</MenuItem>
                        <div className="menulables">
                            <p>All Master</p>
                        </div>
                        <SubMenu className="" icon={<FaLocationDot />} label="Location">
                            <MenuItem className="sub_active" icon={<MdAddLocationAlt />} component={<Link to="/add-location" />}> Add Location </MenuItem>
                        </SubMenu>
                        <SubMenu className="" icon={<TbBrandMastercard />} label="Designation">
                            <MenuItem className="sub_active" icon={<FaArrowTrendUp />} component={<Link to="/add-designation" />}> Add Designation</MenuItem>
                        </SubMenu>
                        <SubMenu className="" icon={<TbTimeDurationOff />} label="Duration">
                            <MenuItem className="sub_active" icon={<GiDuration />} component={<Link to="/duration" />}>Duration</MenuItem>
                        </SubMenu>
                        <SubMenu className="" icon={<FaPersonBreastfeeding />} label="Department">
                            <MenuItem className="sub_active" icon={<MdSpatialAudioOff />} component={<Link to="/department" />}>Department</MenuItem>
                        </SubMenu>
                        <SubMenu className="" icon={<MdOutlineRealEstateAgent />} label="State">
                            <MenuItem className="sub_active" icon={<MdRealEstateAgent />} component={<Link to="/state" />}>State</MenuItem>
                        </SubMenu>
                        <SubMenu className="" icon={<SiPrivatedivision />} label="Division">
                            <MenuItem className="sub_active" icon={<CgPathDivide />} component={<Link to="/division" />}>Division</MenuItem>
                        </SubMenu>
                        <SubMenu className="" icon={<CiBank />} label="Bank">
                            <MenuItem className="sub_active" icon={<CiBank />} component={<Link to="/bank" />}>Bank</MenuItem>
                        </SubMenu>
                        <SubMenu className="" icon={<PiTrainRegionalFill />} label="Region">
                            <MenuItem className="sub_active" icon={<PiTrainRegionalFill />} component={<Link to="/region" />}>Region</MenuItem>
                        </SubMenu>
    
                        <SubMenu className="" icon={<CgDisplayGrid />} label="Dispensary">
                            <MenuItem className="sub_active" icon={<CgDisplayGrid />} component={<Link to="/dispensary" />}>Dispensary</MenuItem>
                        </SubMenu>

                        <SubMenu className="" icon={<MdSensorOccupied />} label="Occupation">
                            <MenuItem className="sub_active" icon={<MdSensorOccupied />} component={<Link to="/occupation" />}>Occupation</MenuItem>
                        </SubMenu>

                        <SubMenu className="" icon={<FaTags />} label="Tags">
                            <MenuItem className="sub_active" icon={<FaTags />} component={<Link to="/tags" />}>Tags</MenuItem>
                        </SubMenu>

                        <SubMenu className="" icon={<MdCastForEducation />} label="Education">
                            <MenuItem className="sub_active" icon={<MdCastForEducation />} component={<Link to="/education" />}>Education</MenuItem>
                        </SubMenu>

                        <SubMenu className="" icon={<FaBehanceSquare />} label="Benefits">
                            <MenuItem className="sub_active" icon={<FaBehanceSquare />} component={<Link to="/benefits" />}>Benefits</MenuItem>
                        </SubMenu>

                        <SubMenu className="" icon={<MdHolidayVillage />} label="Holiday">
                            <MenuItem className="sub_active" icon={<MdHolidayVillage />} component={<Link to="/holiday" />}>Holiday</MenuItem>
                        </SubMenu>

                        <SubMenu className="" icon={<SiPayloadcms />} label="Cms">
                            <MenuItem className="sub_active" icon={<SiPayloadcms />} component={<Link to="/cms" />}>Cms</MenuItem>
                        </SubMenu>

                        <SubMenu className="" icon={<FaSackDollar />} label="SalaryRange">
                            <MenuItem className="sub_active" icon={<FaSackDollar />} component={<Link to="/salary-range" />}>Salary Range</MenuItem>
                        </SubMenu>
                        <SubMenu className="" icon={<FaCalendarDays />} label="Leave">
                            <MenuItem className="sub_active" icon={<FaCalendarDays />} component={<Link to="/leave" />}>Leave</MenuItem>
                        </SubMenu>
                        <SubMenu className="" icon={<FaExchangeAlt />} label="GradeList">
                            <MenuItem className="sub_active" icon={<FaExchangeAlt />} component={<Link to="/gradelist" />}>GradeList</MenuItem>
                        </SubMenu>




                    </Menu>
                </main>
            </Sidebar>
        </>
    );
}
export default Sidebars;
