import React, { useEffect, useState } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { Link } from 'react-router-dom';
import { PiDownloadSimpleFill } from "react-icons/pi";
import ApprovalModal from "./ApprovalModal"
import { ManPowerAcquisitionsSlice } from '../slices/JobSortLIstedSlice/SortLIstedSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';



export default function RequisitionTable() {
    const [visible, setVisible] = useState(false);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const { ManPowerRequisition } = useSelector((state) => state.shortList)

    console.log(ManPowerRequisition, 'this is State of data');


    useEffect(() => {
        let Payloads = {
            "keyword": "",
            "page_no": "1",
            "per_page_record": "1000", "scope_fields": [],
            "status": ""
        }
        dispatch(ManPowerAcquisitionsSlice(Payloads))
    }, [dispatch])



    const handleShow = (e , data) => {
        e.preventDefault()
        setShow(true)
        setVisible(data);
    };


    const rows = ManPowerRequisition.status === 'success' && Array.isArray(ManPowerRequisition.data)
        ? ManPowerRequisition.data.map((key, index) => {
            return {
                id: index + 1,
                fID: index + 1,
                projectDepartment: {
                    name: key?.project_name,
                    department: key?.department_name,
                },
                placeofPosting: {
                    places: key?.place_of_posting?.map((item) =>
                        `${item.location_name}, ${item?.state_name ? item?.state_name : ''}`).join(','),
                },
                status: {
                    value1: key?.status,
                    value2: 'CEO Approval Pending',
                    value3: 'HOD Approval Pending',
                },
                feedBack: {
                    comment_1: "Interpersonal skills are great. Technically sound",
                    comment_2: "Matching the skill sets we required. Considering for CEO round",
                },
                value: key,
                dateofRequest: moment(key?.raised_on).format('DD MMM YYYY'),
                designation: key?.designation_name,
                type: key?.type_of_opening,
                timeFrame: key?.vacancy_frame,
                noofVacancies: key?.no_of_vacancy,
                NoticePeriod: key?.vacancy_frame,
            };
        }) : [];


    // const rows = [
    //     {
    //         id: 1,
    //         fID: '0003',
    //         projectDepartment: {
    //             name: "PMU Global Fund",
    //             department: "The Global Fund Grant - TB",
    //         },
    //         placeofPosting: {
    //             places: "Chhattisgarh,  Uttar Pradesh, Gujurat",
    //         },
    //         status: {
    //             value1: 'Pending',
    //             value2: 'CEO Approval Pending',
    //             value3: 'HOD Approval Pending',
    //         },
    //         feedBack: {
    //             comment_1: "Interpersonal skill are great. Technically sound",
    //             comment_2: "Matching the skill sets we required. Considering for CEO round"
    //         },
    //         "dateofRequest": '12/09/2024',
    //         "designation": 'State M&E Manager',
    //         "type": 'New',
    //         "timeFrame": 30,
    //         "noofVacancies": 6,
    //         "Notice Period": 30,
    //     },
    //     {
    //         id: 2,
    //         fID: '0002',
    //         projectDepartment: {
    //             name: "PMU Global Fund",
    //             department: "The Global Fund Grant - TB",
    //         },
    //         placeofPosting: {
    //             places: "Chhattisgarh,  Uttar Pradesh, Gujurat",
    //         },
    //         status: {
    //             value1: 'Pending',
    //             value2: 'CEO Approval Pending',
    //         },
    //         feedBack: {
    //             comment_1: "Interpersonal skill are great. Technically sound",
    //             comment_2: "Matching the skill sets we required. Considering for CEO round"
    //         },
    //         "dateofRequest": '12/09/2024',
    //         "designation": 'State M&E Manager',
    //         "type": 'New',
    //         "timeFrame": 30,
    //         "noofVacancies": 6,
    //         "Notice Period": 30,
    //     },
    //     {
    //         id: 3,
    //         fID: '0001',
    //         projectDepartment: {
    //             name: "PMU Global Fund",
    //             department: "The Global Fund Grant - TB",
    //         },
    //         placeofPosting: {
    //             places: "Chhattisgarh,  Uttar Pradesh, Gujurat",
    //         },
    //         status: {
    //             value1: 'Pending',
    //             value2: 'CEO Approval Pending',
    //         },
    //         feedBack: {
    //             comment_1: "Interpersonal skill are great. Technically sound",
    //             comment_2: "Matching the skill sets we required. Considering for CEO round"
    //         },
    //         "dateofRequest": '12/09/2024',
    //         "designation": 'State M&E Manager',
    //         "type": 'New',
    //         "timeFrame": 30,
    //         "noofVacancies": 6,
    //         "Notice Period": 30,
    //     },




    // ];

    const columns = [
        {
            field: "fID",
            headerName: "ID",
            width: 50,
            renderCell: (params) => (
                <>
                    <p className="color-blue">{params.row?.fID}</p>
                </>

            ),
        },
        {
            field: "dateofRequest",
            headerName: "Date of Request",
            width: 140,
        },
        {
            field: "projectDepartment",
            headerName: "Project/Department",
            width: 160,
            renderCell: (params) => (
                <div className='projectinfo empinfo lineBreack p-0'>
                    <p>{params.row?.projectDepartment?.name},</p>
                    <p>{params.row?.projectDepartment?.department}</p>
                </div>
            ),
        },
        {
            field: "designation",
            headerName: "Designation",
            width: 300,
        },
        {
            field: "type",
            headerName: "Type",
            type: "number",
            width: 80,
        },
        {
            field: "timeFrame",
            headerName: "Time Frame(In Days)",
            type: "number",
            width: 150,

        },
        {
            field: "noofVacancies",
            headerName: "No. of Vacancies",
            type: "number",
            width: 150,

        },
        {
            field: "placeofPosting",
            headerName: "Place of Posting",
            type: "number",
            width: 150,
            renderCell: (params) => (
                <div className="recomd_tag lineBreack p-0">
                    <span className="">{params.row?.placeofPosting?.places}</span>
                </div>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            type: "number",
            width: 160,
            renderCell: (params) => (
                <div className='stats_txt lineBreack p-0'>
                    <h6 className='stats_hdng'>
                        {params.row?.status?.value1}
                    </h6>
                    {
                        params.row?.value?.activity_data?.length !== 0 &&
                        params.row?.value?.activity_data?.map((item, index) => {
                            return (
                                <span>{item?.name} , Approval {item?.status}</span>
                            )
                        })
                    }
                </div>
            ),
        },
        {
            field: "mprDoc",
            headerName: "MPR Doc",
            type: "number",
            width: 120,
            renderCell: (params) => (
                <a
                    href={params.row?.value?.requisition_form} // Assuming `pdfUrl` holds the URL of the PDF
                    download // This forces the download of the file
                    target="_blank" // Opens in a new tab (optional)
                    rel="noopener noreferrer" // Ensures safe external link opening
                >
                    <button className='downlddoc'>
                        <PiDownloadSimpleFill />
                    </button>
                </a>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            type: "number",
            width: 130,
            renderCell: (params) => (
                <button className='apprvbtn' onClick={(e) =>  handleShow(e , params.row?.value)}>
                    Approve
                </button>
            ),
        },

    ];


    return (
        <>
            <div className="w-100 mainprojecttable requstntable">
                <DataGrid
                    rows={rows}
                    columns={columns}
                    headerClassName="custom-header-class"
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 20]}
                    loading={ManPowerRequisition.status === 'loading'}
                />
            </div>
            <ApprovalModal show={show} onHide={() => setShow(false)} data={visible} />
        </>
    )
}

